/* eslint-disable getter-return */
import angularModule from 'Lessons/angularModule/scripts/lessons_module';
import { getLessonTextFormattedForTutorbot } from 'TutorBot';

/*
    TextImageInteractive defines three sections, text, image, and interactive, and stacks them vertically.
*/
angularModule.factory(
    'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveModel',
    [
        'Lesson.FrameList.Frame.Componentized.Component.Layout.LayoutModel',
        'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveViewModel',
        '$injector',

        (LayoutModel, TextImageInteractiveViewModel, $injector) => {
            const TextModel = $injector.get('Lesson.FrameList.Frame.Componentized.Component.Text.TextModel');
            const ErrorLogService = $injector.get('ErrorLogService');

            return LayoutModel.subclass(function () {
                this.alias('ComponentizedFrame.TextImageInteractive');
                this.extend({
                    ViewModel: TextImageInteractiveViewModel,
                });
                this.setEditorViewModel(
                    'Lesson.FrameList.Frame.Componentized.Component.Layout.TextImageInteractive.TextImageInteractiveEditorViewModel',
                );

                // make sure no one is accidentally using the old api
                ['contentForText', 'contentForImage', 'contentForInteractive'].forEach(prop => {
                    const message = `${prop} is no longer supported. Use ${`static_${prop}`.camelize()}`;
                    Object.defineProperty(this.prototype, prop, {
                        get() {
                            throw new Error(message);
                        },
                        set() {
                            throw new Error(message);
                        },
                    });
                });

                // either one or more of the staticContent properties can be set ...
                this.references('staticContentForText').through('content_for_text_id');
                this.references('staticContentForFirstImage').through('content_for_image_id');
                this.references('staticContentForSecondImage').through('content_for_image_2_id');
                this.references('staticContentForInteractive').through('content_for_interactive_id');
                this.references('staticContentForInteractiveImage').through('content_for_interactive_image_id');
                this.key('context_image_size');
                this.key('context_image_2_size');

                const getImageContext = function (key) {
                    const sizeKey = {
                        staticContentForFirstImage: 'context_image_size',
                        staticContentForSecondImage: 'context_image_2_size',
                    }[key];

                    const size = this[sizeKey];

                    return this.getImageContext(size);
                };
                this.setImageContext('staticContentForFirstImage', getImageContext);
                this.setImageContext('staticContentForSecondImage', getImageContext);

                // ... or a target component can be set, whose viewModel must
                // define contentForTextViewModel, contentForImageViewModel, ...
                this.references('target').through('target_id');

                // Used outside when the frame interfaces with things in the editor.
                // For switching between componentized and non-componentized
                // frame types and for populating the thumbnails
                Object.defineProperty(this.prototype, 'mainTextComponent', {
                    get() {
                        // warning: if this ever changes such that it does not just map to staticContentForText,
                        // see _updateMainTextComponentConfig in editor view model
                        if (this.staticContentForText && this.staticContentForText.isA(TextModel)) {
                            return this.staticContentForText;
                        }

                        return undefined;
                    },
                });

                return {
                    // On challenge frames, in order to prevent people on test lessons from completing a frame
                    // and then refreshing before they have been moved on to the next frame,
                    // we update the frameBookmarkId once the frame is completed. On these no_interaction
                    // frames, however, we don't want to push the frameBookmarkId until the learner
                    // clicks to move on
                    savesProgressOnComplete: false,

                    // tested at generateTutorBotDescription.spec.ts
                    generateTutorBotDescription() {
                        try {
                            const returnVal = {
                                type: this.editor_template,
                                id: this.frame().id,
                                hasMainImage: false,
                                instructions: this.frame().miniInstructions,
                            };
                            if (this.mainTextComponent) {
                                returnVal.mainText = getLessonTextFormattedForTutorbot(
                                    this.mainTextComponent.text,
                                    this.mainTextComponent.modals?.map(m => m.text) || [],
                                );
                            }

                            if (this.staticContentForFirstImage || this.staticContentForSecondImage) {
                                returnVal.hasMainImage = true;
                            }
                            return returnVal;
                        } catch (e) {
                            ErrorLogService.notifyInProd(e, null, {
                                frameId: this.frame().id,
                                lessonId: this.frame().lesson().id,
                            });
                            return null;
                        }
                    },

                    getImageContext(size) {
                        return size === 'short' ? 'shortContextImage' : 'tallContextImage';
                    },
                };
            });
        },
    ],
);
